(function(root) {
  // Галерея

  $('.gallery__for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.gallery__nav',
    draggable: false,
    infinite: false
  });

  $('.gallery__nav').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    asNavFor: '.gallery__for',
    dots: true,
    centerMode: false,
    focusOnSelect: true,
    variableWidth: true,
    infinite: false
  });

  $('.gallery__nav .slick-slide').on('click', function() {
    $(this).addClass('slick-choose').siblings().removeClass('slick-choose');
  })

  // Модалки

  $('.modal').iziModal();

  $('[data-modal]').on('click', function(e) {
    e.preventDefault();

    var id = $(this).data('modal');

    $('#'+id).first().iziModal('open');
  });

  $('.menu').on('click', function(e) {
    e.preventDefault();

    $(this).toggleClass('menu_open');
    // $('.header__nav').toggleClass('header__nav_mobile');
  });

  // social tabs

  $('.social__tabs li').on('click', function(e) {
    e.preventDefault();

    var index = $(this).index(),
        social = $(this).closest('.social'),
        content = $(social).find('.social__content').get(index);

    $(social).find('.social__content').hide();
    $(social).find('.social__tabs li.active').removeClass('active');
    $(this).addClass('active');
    $(content).show();

  })

  $('.social__tabs li').first().trigger('click');


})(window);
